<template>
  <div class="box">
    <p style="text-align: center;font-size: 18px"> 中国联通客户入网服务协议</p>
    <p>甲方：</p>
    <p>乙方：中国联合网络通信有限公司分公司</p>
    <p>
      根据《中华人民共和国合同法》、《中华人民共和国电信条例》等有关法律法规的规定，甲乙双方在平等、自愿、公平、诚信的基础上，基于对乙方通信服务的了解和需求，甲方自愿申请成为乙方客户，并达成协议如下：
    </p>

    <p>·入网要求及业务办理</p>
    <p>
      （一）甲方办理入网、变更手续时，应提交以下登记资料：
    </p>
    <p>
      1、个人客户：提供个人有效身份证件原件，委托他人办理的应同时提交本人及代理人有效身份证件原件及授权委托书。利用个人临时身份证件原件办理的，须同时提供公安部门出具的身份证领取凭证、身份信息辅助证件（驾照、医保卡或户口本等），不得委托他人办理。
    </p>
    <p>
      2、单位客户：提供单位有效证件原件（或加盖公章的单位有效证件复印件）、经办人有效身份证件原件、加盖公章的单位介绍信等，单位客户办理移动电话（含无线上网卡）业务时，须同时提供实际使用人有效身份证件。

    </p>
    <p>
      3、甲方户籍所在地（以有效身份证件的住址为准）或法定住所地（单位有效证件上单位注册地）不在本地的，应该按照乙方的要求办理相应的担保手续。
    </p>
    <p>（二）甲方应使用国家给予入网许可标志的终端设备，终端设备应具备支持所选服务的相应功能，如无法支持所选服务，<span class="bigsize">甲方应自行承担后果，并向乙方全额支付其所选服务的全部费用。</span></p>
    <p>
      （三）甲方欲将业务号码过户时，应先交清所有费用，过户时须由双方持有效身份证件原件办理。如有特殊情况，甲方可委托他人办理，但应同时提交本人及代理人有效身份证件原件及授权委托书。

    </p>
    <p>
      （四）在甲方通过过户成为新机主的情形下，如因原机主未亲自到场办理过户而导致原机主就此提出异议，甲方应无条件放弃因过户产生的全部权益，并承担由此对原机主及对乙方造成的一切损失。过户代理人对此承担连带责任。

    </p>
    <p> （五）甲方采用担保人方式入网的，办理过户业务时，甲方须提交担保人出具的书面文件，说明担保人同意继续担保或要求终止担保。新机主应符合本协议第一条第（一）款的条件。:</p>

    <p>
      （六）甲方本人未到场，由代理人、经办人办理各类业务的，甲方应承担由此产生的责任与义务；代理人、经办人承担连带责任。
    </p>
    <p>
      （七）根据《中华人民共和国反恐怖主义法》第二十一条之规定以及六部委《关于防范和打击电信网络诈骗犯罪的通告》等文件要求，甲方持同一有效身份证件在乙方处办理入网，若甲方已有或本次办理后将超过5张及以上在网的移动号卡（含上网卡），乙方将按照规定不得为甲方办理涉及新增移动号卡（含上网卡）的相应业务。
    </p>
    <p> ·费用标准和费用交纳</p>
    <p> （一）乙方应在国家电信资费主管部门允许的范围内设定资费标准、向客户明码标价、公告交费期限信息；甲方应在乙方明示的期限内足额交纳各项通信费用。</p>
    <p> （二）甲方使用乙方提供的资费套餐方案，套餐有效期为一年（双方特殊约定的除外）。套餐到期后，双方如无异议，有效期逐年自动顺延。如无特殊约定，甲方在有效期内或到期后可更换套餐。</p>
    <p>
      （三）如遇国家统一调整通信费用标准，则按国家统一规定执行。如遇乙方发布、调整资费，自乙方公告确定的生效日起执行新的资费标准。在乙方公告确定的生效日前，甲方未提出异议的视为同意，协议继续履行；甲方提出异议且未能与乙方达成一致的，甲方向乙方结清全部未付款项后本协议自动终止。
    </p>
    <p>
      （四）若甲方为后付费用户，甲方应按时交纳通信费用。甲方未在约定期限内足额交纳通信费用的，乙方每日加收所欠费用的３‰作为违约金，并有权暂停甲方服务；甲方交清欠费和违约金后，除甲方明确提出不开通或已销号外，乙方应在24小时内恢复甲方服务。对前述情形，乙方将保留追缴欠费、违约金及向征信机构提供用户欠费信息的权利，也可用通知单、委托第三方等形式追缴欠费。
    </p>
    <p> （五）若甲方为预付费用户，则必须保证账户上有充足的款项。如甲方账户满足消费条件的余额不足，乙方有权限制或停止向甲方提供服务，且乙方可不再另行通知。</p>
    <p> （六）甲方定制第三方增值业务或其它收费业务，乙方可以代第三方向甲方收取信息费、功能费等，甲方使用第三方提供的增值业务或其它收费业务由第三方制定收费标准并公布。</p>
    <p> （七）甲方如需开通国际业务、港澳台业务，应按乙方规定交纳相应费用。</p>
    <p> （八）甲方不应以不知晓终端产生网络流量的原因（例如终端软件自动升级）为由，拒绝支付或要求减免相应费用。</p>
    <p> ·客户权益</p>
    <p> （一）网络服务</p>
    <p> 1、乙方在现有技术条件下的网络覆盖范围内向甲方提供通信服务。</p>
    <p> 2、乙方提供的网络服务应符合国家规定的通信质量标准。</p>
    <p> （二）客户服务</p>
    <p> 1、乙方在承诺的网络覆盖范围内按照不低于《电信服务规范》的标准向客户提供服务。</p>
    <p> 2、<span
        class="bigsize">乙方向甲方提供客户服务电话10010、网上营业厅www.10010.com等渠道，以便甲方了解乙方各项服务。</span>乙方还应向甲方免费提供通话所在地（仅限大陆地区，不含港澳台）火警119、匪警110、医疗急救120、交通事故报警122等公益性电信服务。
    </p>
    <p> 3、乙方向甲方提供需要甲方支付月功能费的服务项目时，应征得甲方同意；乙方开通服务项目让甲方进行体验时，不收取体验服务项目月功能费。</p>
    <p> 4、对于甲方通信服务开通/关闭申请，乙方应在承诺的时限内操作完成（双方另有约定的除外）。乙方超过时限未及时开通/关闭的，应减免甲方由此产生的通信费用。</p>
    <p>
      5、甲方个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别甲方身份或者反映甲方活动情况的各种信息。乙方严格按照《中华人民共和国网络安全法》等法律法规的相关要求，对其在提供服务过程中收集、使用的甲方个人信息履行保护义务。在甲方使用乙方提供的服务时，乙方依据法律法规、监管政策和本协议约定收集、存储、使用、披露和保护甲方的个人信息。
    </p>
    <p>
      6、甲方理解并同意，乙方可以通过业务受理系统登记、纸质返档，通过网络接收、读取并记录等方式，以提供服务为目的，在业务活动中收集、使用甲方提供的和甲方使用服务过程中形成的个人信息。甲方同意乙方可以为向甲方提供服务、改进和完善服务、提供个性化或定制化产品与服务、评估服务中的促销与推广活动效果、软件认证或升级等目的使用个人信息，乙方有权依法对包含甲方在内的整体用户数据进行分析并加以利用，以便于为甲方提供更好服务。
    </p>
    <p>
      7、甲方理解并同意乙方将收集的甲方个人信息共享给乙方关联公司在其业务目的和范围内使用；甲方亦同意乙方为履行、改善和改进乙方在本协议项下的服务之目的将收集的必要的甲方个人信息共享给乙方的授权合作伙伴。除前述明确约定情形外，未经甲方同意，乙方不向其他第三方提供甲方个人信息。
    </p>
    <p> 8、甲方本人持有效证件可通过自有营业厅或乙方指定的其他渠道，对其个人信息进行查询、更正。</p>
    <p>
      9、乙方依法保证甲方的信息资料安全、通信自由和通信秘密。但以下情形不应视为乙方违反个人信息保护义务：（1）因追缴欠费需要，向第三方机构和征信机构提供用户个人相应信息及欠费信息的；（2）为向甲方提供更好的服务，通过短信、彩信、wappush、电话、电子邮件、信函、微博、微信等方式向甲方发送业务服务信息或进行互动沟通的；（3）司法、行政机关依法要求乙方提供协助与配合，乙方应给予协助与配合的；（4）出于国家安全、国防安全、公共安全、公共卫生和重大公共利益目的而未经授权的披露行为；（5）出于维护甲方或其他个人生命、财产等重大合法权益但又难以得到甲方同意的；（6）法律法规另有规定的。
    </p>
    <p> 10、乙方非常重视用户的隐私和个人信息保护，并会尽全力保护用户的个人信息安全可靠。关于乙方如何收集、存储、使用、披露和保护您的个人信息，请见乙方不时更新、修订并公布的《中国联通用户隐私政策》，网址为www.10010.com。
    </p>
    <p> ·风险与责任</p>
    <p>
      （一）甲方应保证入网、变更登记资料真实有效、准确完整，并有义务配合乙方对登记资料进行查验。甲方登记资料如有变更，应主动办理变更手续。甲方如需将通信卡转让给他人使用，应在乙方营业厅办理过户。因甲方提供的客户资料不详、不实或变更后未及时通知乙方等原因，使乙方无法向甲方提供服务或甲方无法享受到乙方提供的相关服务，乙方无需向甲方承担任何责任。<span
        class="bigsize">如乙方发现因甲方登记资料失实或者甲方未配合及时更正，或乙方发现甲方业务号码存在违法使用、违规外呼、呼叫频次异常、超约定用途使用、违规或违约转让、转租、转售、业务号码被公安机关通报、该号码因上述问题被投诉较多等情况的，乙方有权暂停、终止甲方服务，且乙方无需向甲方承担任何责任。</span>
    </p>
    <p class="bigsize">
      （二）甲方应妥善保管自己的用户号码、通信卡、终端、宽带账号，若发现丢失或被盗用，可及时拨打客户服务电话或到乙方营业网点办理暂时停机或修改账号密码手续；并可向公安机关报案，乙方应配合公安机关调查，但乙方不承担上述情形对甲方所造成的后果；如甲方将名下号码交予他人使用，因此引起的义务与责任仍由甲方承担。
    </p>
    <p> （三）甲方应妥善管理其服务密码。服务密码是甲方办理业务的重要凭证，甲方入网后应立即修改初始服务密码。<span
        class="bigsize">凡使用服务密码定制、变更或终止业务的行为均被视为甲方或甲方授权的行为，因此引起的义务与责任均由甲方承担</span>。</p>
    <p class="bigsize"> （四）乙方按照现有技术标准为甲方提供通信服务，但乙方无法控制第三方利用各种手段从事违法行为等情况的发生，如因第三方的恶意行为造成甲方的损失，乙方对此将不承担相关责任。</p>
    <p> （五）甲方使用固网及宽带业务时，未经有关部门批准许可，不得自行更改其使用性质，不得开设各类服务站点，不得利用计算机互联网络进行任何经营性服务活动，否则乙方有权停止服务，依法追缴各项费用和违约金，并不承担任何责任。</p>
    <p> （六）若非双方另行约定，乙方提供的宽带接入线路最多限定X个终端上网使用，未经乙方书面同意，甲方不得私自组网连接其他终端设备。</p>
    <p> （七）<span class="bigsize">甲方退网时，甲方租赁或乙方免费提供给甲方使用的终端设备，应归还乙方或按照甲乙双方相关约定处理。</span></p>
    <p> （八）<span class="bigsize">甲方在欠费情况下，乙方有权拒绝为甲方开办其他业务（含移网、固网、宽带等所有业务），直至甲方补交全部欠费及违约金</span>。</p>
    <p> （九）乙方在受理业务（服务）后，将进行线路资源核查，对于不具备开通条件的，在乙方告知甲方后，本协议自动终止，乙方退还甲方已交纳费用，但不承担其他责任。</p>
    <p> （十）在固定电话开通并正常使用前（含新装、移机、改号等），甲方不能将已选中号码通知他人或进行宣传，否则由此所造成的后果及损失，由甲方自行承担。</p>
    <p> （十一）<span class="bigsize">甲方所办理的数据业务上下行速率标称值仅为乙方提供的数据业务上下行速率最高值，乙方不能保证在任何情况下均能达到标称值，甲方对此表示知悉并认可</span>。</p>
    <p> （十二）<span
        class="bigsize">按照公平使用原则，乙方将对甲方的移动数据流量进行降速或封顶限制。甲方每月的移动数据流量达到降速条件时，乙方可对甲方当月的上网服务降速，次月自动恢复；甲方每月的移动数据流量达到或超出流量封顶额度时，乙方可暂停甲方当月的上网服务，次月自动恢复</span>。
    </p>
    <p> （十三）甲方未付的通信费用达到信用额度时（信用额度是指用户可以用于透支消费的最高通信费用额度），应及时补充交纳通信费用；当甲方未付的通信费用超过信用额度时，乙方有权暂停甲方网络服务（超过信用额度停机不受约定交费期限的限制）。
    </p>
    <p>
      （十四）甲方发布违法信息、违反公序良俗内容的信息，或未经接收客户同意大量发布商业广告等其他骚扰信息、拨打骚扰电话等不当行为，以及利用乙方提供的服务从事违法犯罪活动的，乙方有权依据行业主管部门、有关行政机关的指令、客户举报或投诉，以及乙方制定的为保障公众利益的规范，关闭甲方信息发送功能，或暂停直至终止服务，由此造成的后果由甲方承担。
    </p>
    <p> （十五）因甲方原因造成的通信卡密码丢失、锁卡或被他人获取造成的损失，乙方不承担责任，甲方不能以此为由拒绝按本协议约定支付通信费用。</p>
    <p> （十六）为了更好地为甲方提供与本套餐相关的服务，甲方同意由乙方将甲方的电话号码等信息提供给乙方合作的第三方。</p>
    <p> （十七）携号转网相关约定：</p>
    <p> 1、甲方有权选择并依据《携号转网服务管理暂行办法》（以下简称“《携号转网管理办法》”）规定申请办理携号转网服务。</p>
    <p> 2、办理携号转网过程中，乙方需将必要的甲方个人信息提供给携号转网集中业务管理系统，用于比对携入方和携出方登记的证件信息是否一致。</p>
    <p>
      3、甲方申请携号转网服务的号码应同时满足以下条件：（1）已在携出方办理真实身份信息登记；（2）号码处于正常使用状态（非挂失、停机等）；（3）与携出方结清申请携号转网号码的已出账电信费用，如有未出账的电信费用（包括但不限于国际漫游费用等），与携出方已约定缴费时间和方式；（4）与携出方无在网约定期限限制的协议，或已解除在网期限限制；（5）申请携号转网的号码距离上次携转时间已满120日（自然日）。
    </p>
    <p> 4、因技术与设备等差异，甲方携号转网成功后可能需要自行更换手机。</p>
    <p> 5、如甲方使用充值卡在原有卡上预存费用，在携号转网成功后将不能继续使用原有卡上剩余费用。</p>
    <p> 6、如甲方从其他网络携号转入乙方网络，甲方在原网络享受的积分、信用度、VIP级别及各种优惠将不能在乙方网络继续使用。</p>
    <p> 7、如甲方办理携号转网，可能会在启用新卡的时间点和网络切换期间出现短时间的通信异常。</p>
    <p> 8、甲方可在办理携号转网业务当日（乙方营业时间内）撤销携号转网业务申请。</p>
    <p> 9、甲方在携号转网成功后仍需与携出方结清通信费用。</p>
    <p> 10、如甲方从其他网络携号转入乙方网络，携出方和乙方收到携号转网成功生效结果告知后，网络切换完成。网络切换完成后，甲方在乙方办理的新移动电话卡激活启用，由乙方为甲方提供电信服务。</p>
    <p>
      11、本协议未约定的携号转网事项，双方按照《携号转网管理办法》规定及双方就携号转网业务签订的具体文件（包括但不限于补充协议、业务受理单等）约定执行。本协议关于携号转网业务的约定如与《携号转网管理办法》规定及双方携号转网具体文件约定发生冲突，以《携号转网管理办法》和双方携号转网具体文件内容为准。
    </p>
    <p> ·违约责任</p>
    <p> （一）一方违约给对方造成损失的，应当依法承担赔偿责任，但违约方应承担的赔偿损失的责任范围不包括守约方未实现的预期利润或利益、商业信誉的损失、丢失的数据本身及因数据丢失引起的损失、守约方对第三方的责任及其他间接损失。</p>
    <p> （二）因不可抗力导致本协议部分或全部不能履行的，双方可部分或全部免除责任。</p>
    <p> ·协议的变更与解除</p>
    <p> （一）乙方在本协议外以公告、使用手册、资费单页等书面形式公开做出的服务承诺，甲方办理各类业务所签署的表单、业务协议、须知等均自动成为本协议的补充协议；与本协议冲突部分以补充协议为准，补充协议中未约定部分以本协议为准。</p>
    <p> （二）<span
        class="bigsize">甲方要求终止服务（双方另有约定的除外）申请办理拆机或销户的，若甲方为后付费且申请办理拆机或销户时当月的应付费用未出账，或甲方为预付费但预存费用可能不足以支付当月应付费用的，则应按照乙方要求预存一定数额的通信费，次月按照乙方业务规定和双方约定结清相关费用后如有剩余的，乙方将剩余费用退还甲方</span>。
    </p>
    <p> （三）<span class="bigsize">有下列情形之一的，乙方有权单方解除协议，收回号码或账号并终止服务，并保留追究甲方违约责任的权利：</span>。</p>

    <p class="bigsize"> 3、甲方以担保等方式取得号码使用权的，如担保人违反保证条款或有确1、甲方提供的有效证件（包括代理人/经办人提供的有效身份证件）虚假、不实；</p>
    <p class="bigsize"> 2、甲方自行安装未取得入网许可或可能影响网络安全或网络服务质量设备的；切证据证明担保人无能力履行保证责任的；</p>
    <p class="bigsize"> 4、甲方未办理相关手续，自行改变电信业务使用性质或私自转让租用权的；</p>
    <p class="bigsize"> 5、甲方欠费停机后3个月仍未交清通信费用和违约金的;</p>
    <p class="bigsize"> 6、业务（服务）超过约定有效期的；</p>
    <p class="bigsize"> 8、乙方收到国家行政管理部门通知要求停止甲方服务的；</p>
    <p class="bigsize"> 9、产品在约定期限内未开通、激活的；</p>
    <p class="bigsize"> 10、违规外呼、呼叫频次异常的；</p>
    <p class="bigsize"> 11、法律法规规定的其他情形。</p>
    <p> （四）因技术进步或国家政策等原因导致本协议（部分或全部）无法继续履行的，乙方保留对电信业务（服务）做出调整的权利，调整前乙方应按照有关规定发布公告并提出相应解决方案。<span
        class="bigsize">甲方可就解决方案与乙方协商，但不得要求乙方继续履行本协议</span>。</p>
    <p> ·协议争议</p>
    <p> 有关协议争议，双方可沟通协商解决；协商不成的，甲方可向当地通信管理局或消费者协会申请进行调解；任何一方均可向乙方住所地的人民法院起诉。</p>
    <p> ·协议生效</p>
    <p>
      在本协议签约页面点击确认签署本协议前，请确保您已充分阅读并完全同意本协议内容，一旦您点击确认签署本协议，即表明您已充分阅读并完全同意本协议内容，本协议即在您与乙方（中国联合网络通信有限公司${protocolCity}分公司）之间产生法律效力。如果您不同意本协议的任意内容，或者无法准确理解乙方（中国联合网络通信有限公司${protocolCity}分公司）对条款的解释，请不要进行后续操作。本协议一式两份，甲乙双方各执一份，有效期一年。协议到期后，双方如无异议，有效期以一年为周期自动顺延。
    </p>
    <p class="bigsize"> （甲方承诺：本人已经充分、完整阅读并理解本协议所述全部条款及条件。如申请或使用乙方提供的业务，即视为您已阅读并同意受本协议的约束。）</p>
    <p> 甲方：</p>
    <p> 乙方：中国联合网络通信有限公司分公司</p>
    <p> 甲方用户号码：</p>
    <p> 签署日期:</p>
    <p> 签署日期：</p>
    <p style="height:50px"> </p>



  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  }
}
</script>

<style lang="less" scoped>
.lefttext {
  // margin-left: 30px;
  text-indent: 20px;
}

p {
  /* padding: 0px 20px; */
  margin: 5px 15px;
  /* text-align: center; */
}

.bigsize {
  font-weight: 600;
}

.box {
  // padding-top: 65px;
  // padding-bottom: 30px;

  height: 100%;
  background-color: #fff;
}
</style>