<template>
  <div class="lianghaomain">
    <div>
      <!-- 背景 -->
      <div class="container">
        <van-image fit="cover" :src="src" />
      </div>
      <!--导航-->

      <div class="nav_bar_top">
        <div style="
            width: 95%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
          ">
          <div style="height: 100%">
            <img :src="back" alt="" style="height: 0.6486rem; width: 0.6486rem" @click="close" />
          </div>
          <div style="">
            <p @click="onClickRight">交易记录</p>
          </div>
        </div>
      </div>
      <!-- <van-nav-bar title="" left-text="" right-text="交易记录" left-arrow @click-left="onClickLeft" @click-right="onClickRight"
      style="" /> -->

      <!--  -->
      <div style="
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        ">
        <!-- <div class="div_yellow">
          <p style="margin-bottom:0.1351rem;font-weight: 600;font-size: 0.4324rem;text-align: center;">您已选择“万民5G大王卡”</p>
          <p style="text-align: center;">套餐内通用流量30GB,500分钟语音通话</p>
        </div> -->
        <div class="div_search">
          <div style="
              width: 95%;
              border: 1px solid #ffbc73;
              height: 1.0811rem;
              border-radius: 20px;
              display: flex;
              padding: 0 0.4054rem 0 0.4054rem;
              align-items: center;
              justify-content: space-between;
            ">
            <van-field v-model="text" label="" clearable placeholder="请输入心仪的数字" type="digit" maxlength="4" style="
                height: 0.5405rem;
                width: 85%;
                line-height: 0.5405rem;
                background-color: #fff;
              " :border="false" @clear="clearinfos" />
            <div style="color: #007aff" @click="searchPhones">搜索</div>
          </div>
        </div>
      </div>
      <!-- biaoqianye -->
      <div style="width: 95%; margin: 0 auto">
        <van-tabs v-model="active" @change="changeTabs">
          <van-tab title="靓号" name="2"></van-tab>
          <van-tab title="普通" name="1"></van-tab>
        </van-tabs>
      </div>
      <!--靓号  -->
      <div style="width: 95%; display: flex; margin: 0 auto" class="lianghao_list_item">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad"
          style="display: flex; flex-wrap: wrap">
          <van-cell v-for="(item, i) in list" :key="i" @click="tanchuangzhanshi(item)">
            <div class="phone-number">
              <span>{{ item.toString().slice(0, 3) }}-</span><span>{{ item.toString().slice(3, 7) }}-</span><span
                style="color: #ee4648">{{
                  item.toString().slice(7, 11)
                }}</span>
            </div>
            <div class="image-wrapper">
              <p style="font-size: 0.3243rem; color: #999999">中国联通</p>
              <img style="width: 0.6486rem; height: 0.6486rem" :src="require('../img/fire.png')" alt="" />
            </div>
          </van-cell>
        </van-list>
      </div>
      <!-- 购买弹框 -->
      <van-popup v-model="popupVisible" position="bottom" v-if="popupVisible"
        style="height: 17rem; background-color: rgba(0, 0, 0, 0)">
        <div style="
            height: 14.9rem;
            width: 100%;
            background-color: #fff;
            position: absolute;
            bottom: 0;
            border-radius: 0.5405rem 0.5405rem 0rem 0rem;
          ">
          <div class="buy_img" ref="imageTofile">
            <!-- <van-image fit="cover" :src="require('../img/phone.png')" /> -->
            <div class="buy_info">
              <div style="
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.2162rem;
                ">
                <span class="phone_info"><span>{{ tsphone.one }}-</span><span>{{ tsphone.two }}-</span><span>{{
                  tsphone.three }}</span></span>
                <img v-if="active == '2'" :src="require('../img/liang.png')" alt="" />
              </div>
              <div>
                <span class="liuliang"> ·30G全国流量</span><span class="liuliang">· 500分钟语音通话</span>
              </div>
              <div class="songhuo">送货方式：快递送货</div>
            </div>
          </div>
          <!-- <van-form ref="form" :model="formData" label-width="6em" @submit="onSubmit"
          style="margin-top:2.4324rem;display: flex;flex-direction: column;align-items: center;">
          <van-field v-model="formData.name" name="name" label="姓名" placeholder="请输入姓名" class="form_item" clearable
            :rules="[{ required: true, message: '请填写姓名' }]"></van-field>
          <van-field v-model="formData.idCard" name="idCard" label="身份证号" placeholder="请输入身份证号" class="form_item"
            clearable :rules="[{ pattern, message: '请输入正确身份证号' }]" :maxlength="18">
          </van-field>
          <van-field v-model="fieldName" is-link readonly label="地区" placeholder="请选择所在地区" @click="showArea = true"
            class="form_item" :validate-trigger="onSubmit" :rules="[{ required: true, message: '请填写地区' }]" />
          <van-popup v-model="showArea" round position="bottom" class="popup_index">
            <van-area title="请选择城市" :area-list="areaList" @change="changeCity" :columns-num="3" :visible-item-count="3"
              style="height:250px" :active-index="activeIndex2" :value="fieldValues">
            </van-area>
            <div class="confirm-btn" @click="onConfirms">确定</div>
          </van-popup>
          <van-field v-model="formData.address" name="address" label="详细地址" placeholder="请输入详细地址" class="form_item"
            clearable :rules="[{ required: true, message: '请填写详细地址' }]"></van-field>
          <van-field v-model="formData.mobile" name="mobile" label="手机号" type="tel" placeholder="请输入手机号" class="form_item"
            clearable :maxlength="11" :rules="[{ pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码' }]"></van-field>
          <div class="terms-container">
            <van-checkbox v-model="formData.agree" name="agree" required>
            </van-checkbox>
            <div class="terms_discrip"> <span><span style="margin-right:8px">我已阅读</span> <span
                  style="color: #FF7B22;"><span @click="shows = true, pdfsrc = '1'"
                    style="margin-right:8px">《关于客户个人信息收集、使用规则的公告》</span>
                  <span style="margin-right:8px" @click="shows = true, pdfsrc = '2'">《中国联通客户入网服务协议》</span>
                  <span @click="shows = true, pdfsrc = '3'">《中国联通客户移动业务靓号协议》</span>
                </span></span></div>
          </div>
          <van-button round block type="info" class="sub_button" native-type="submit" :loading="loadings"
            loading-text="加载中...">提交</van-button>
        </van-form> -->

          <van-form ref="form" :model="formData" label-width="6em" style="
              margin-top: 2.4324rem;
              display: flex;
              flex-direction: column;
              align-items: center;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
            ">
            <van-field v-model="formData.name" name="name" label="姓名" placeholder="请输入姓名" class="form_item"
              clearable></van-field>
            <van-field v-model="formData.idCard" name="idCard" label="身份证号" placeholder="请输入身份证号" class="form_item"
              clearable :maxlength="18">
            </van-field>
            <van-field v-model="fieldName" is-link readonly label="地区" placeholder="请选择所在地区" @click="showArea = true"
              class="form_item" :validate-trigger="onSubmit" />

            <van-field v-model="formData.address" name="address" label="详细地址" placeholder="请输入详细地址" class="form_item"
              clearable></van-field>
            <van-field v-model="formData.mobile" name="mobile" label="手机号" type="tel" placeholder="请输入手机号"
              class="form_item" clearable :maxlength="11"></van-field>
            <div class="terms-container">
              <van-checkbox v-model="formData.agree" name="agree" required>
              </van-checkbox>
              <div class="terms_discrip">
                <span><span style="margin-right: 8px">我已阅读</span>
                  <span style="color: #ff7b22"><span @click="(shows = true), (pdfsrc = '1')"
                      style="margin-right: 8px">《关于客户个人信息收集、使用规则的公告》</span>
                    <span style="margin-right: 8px" @click="(shows = true), (pdfsrc = '2')">《中国联通客户入网服务协议》</span>
                    <span @click="(shows = true), (pdfsrc = '3')">《中国联通客户移动业务靓号协议》</span>
                  </span></span>
              </div>
            </div>
            <van-button round block type="info" class="sub_button" @click="onSubmit" :loading="loadings"
              loading-text="加载中...">立即下单</van-button>
          </van-form>

          <!-- pdf -->
          <van-overlay :show="shows" :lock-scroll="false">
            <!-- <div class="wrapper"> -->
            <div class="pdf_block" id="pdfBox">
              <van-nav-bar title="" left-text="" right-text="" left-arrow @click-left="shows = false" />
              <!-- <iframe scrolling="no" :src="pdfSrc1" frameborder="0" v-if="pdfsrc == '1'" name="frame1" id="frame1"
              width="100%" onload="this.height=frame1.document.body.scrollHeight"></iframe>
            <iframe scrolling="no" :src="pdfSrc2" frameborder="0" v-if="pdfsrc == '2'" name="frame1" id="frame2"
              width="100%" onload="this.height=frame2.document.body.scrollHeight"></iframe>
            <iframe scrolling="no" :src="pdfSrc3" frameborder="0" v-if="pdfsrc == '3'" name="frame1" id="frame3"
              width="100%" onload="this.height=frame3.document.body.scrollHeight"></iframe> -->
              <div v-if="pdfsrc == '1'" class="scroll">
                <pdfoneVue></pdfoneVue>
              </div>
              <div v-if="pdfsrc == '2'" class="scroll">
                <pdftwoVue></pdftwoVue>
              </div>
              <div v-if="pdfsrc == '3'" class="scroll">
                <pdfthreeVue></pdfthreeVue>
              </div>
            </div>
            <!-- </div> -->
          </van-overlay>
        </div>
      </van-popup>
      <van-popup class="paypopup" v-model="payshow" closeable position="bottom" :style="{ height: '9rem' }">
        <div class="popup-inner">
          <div class="title" style="
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              text-align: center;
              margin-bottom: 40px;
            ">
            确认支付
          </div>
          <div class="price color333" style="
              text-align: center;
              font-weight: 500;

              color: #3b99ff;
            ">
            <span class="fz18">￥</span>
            <span class="price-num" style="font-size: 28px">{{ price }}</span>
          </div>

          <van-radio-group v-model="payType">
            <van-cell style="margin-bottom: 0.5405rem" :border="false">
              <template #title>
                <van-image width="24" height="24" :src="weixin" style="vertical-align: middle; margin-right: 6px" />
                <span style="line-height: 24px">微信</span>
              </template>
              <template #right-icon>
                <van-radio name="1" />
              </template>
            </van-cell>
            <van-cell :border="false" style="margin-bottom: 0.5405rem">
              <template #title>
                <van-image width="24" height="24" :src="zhifubao" style="vertical-align: middle; margin-right: 6px" />
                <span style="line-height: 24px">支付宝</span>
              </template>
              <template #right-icon>
                <van-radio name="2" />
              </template>
            </van-cell>
          </van-radio-group>

          <van-button type="info" class="bottom-btn" style="
              margin-top: 0.5405rem;
              width: 100%;
              height: 48px;
              background: linear-gradient(360deg, #007aff 0%, #a3c6ff 100%);
              border-radius: 24px;
            " @click="pay">确认付款</van-button>
        </div>
      </van-popup>
      <van-popup v-model="showArea" round position="bottom" class="popup_index">
        <van-area title="请选择城市" :area-list="areaList" @change="changeCity" ref="areaadress" :columns-num="3"
          :visible-item-count="3" style="height: 250px" :active-index="activeIndex2" :value="fieldValues">
        </van-area>
        <div class="confirm-btn" @click="onConfirms">确定</div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import {
  getList,
  xiadansuohao,
  searchAreaInfo,
  getUserInfo,
  payorder,
} from "@/api/lianghao/index";

import { fuli_yundian_pay } from "@/common";
// import { getList, } from "@/api/lianghao/index";

import pdfoneVue from "@/views/lianghao/shouye/pdfone.vue";
import pdftwoVue from "@/views/lianghao/shouye/pdftwo.vue";
import pdfthreeVue from "@/views/lianghao/shouye/pdfthree.vue";
import html2canvas from "html2canvas";

export default {
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }

    if (this.$route.query.session) {
      this.$store.commit("setSession", this.$route.query.session);
      this.session = this.$route.query.session;
    }

    this.searchAdress();
  },
  components: {
    pdfoneVue,
    pdftwoVue,
    pdfthreeVue,
  },
  data() {
    return {
      price: "",
      session: null,
      zhifubao: require("../img/alipay-icon.png"),
      weixin: require("../img/weChat-icon.png"),
      pdfsrc: "",
      // pdfSrc1: ('https://ydcp.oss-cn-beijing.aliyuncs.com/1/csjfkl-we-1223-sdf-3d336764a5e8-4e78-44bf-861f-4efd39f14b6f.pdf#view=FitH,top&toolbar=0'),
      // pdfSrc2: ('https://ydcp.oss-cn-beijing.aliyuncs.com/1/qweysdi-23-sdf-34fefa64f7-e188-40b5-950d-59f9d91f8836.pdf#view=FitH,top&toolbar=0'),
      // pdfSrc3: ('https://ydcp.oss-cn-beijing.aliyuncs.com/1/oweur-23sdf--234-ssdd60b4615-3cbb-461b-86ab-1d5f1b196bf0.pdf#view=FitH,top&toolbar=0'),
      loadings: false,
      loading: false,
      // pdfSrc: '',
      shows: false,
      payshow: false,
      tsphone: {
        one: "",
        two: "",
        three: "",
      },
      id: "",
      fieldValues: "", // 地区编码
      fieldValueshen: "",
      fieldValuesshi: "",
      fieldName: "", // 地区名
      hasChanged: false, // 如果没有改变 则为false  如果滑动改变了地区 则为true
      cascader: "",
      showArea: false,
      pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
      src: require("../img/phones.png"),
      back: require("../img/return.png"),
      // show: false,
      // 2
      // fieldValue: '天津市',
      cascaderValue: "",
      // 选项列表，children 代表子选项，支持多级嵌套
      areaList: {},
      text: "",
      active: "2",
      list: [],

      payType: "1", //支付類型
      orderId: "", //上個頁面的參數
      // 成功失败
      payStatus: false, //成功失败弹框
      success: false, //判定支付成功还是失败
      // list:{},//传给原生的数据
      // 2
      finished: false,
      activeIndex2: [0, 0, 0],
      // selectedArea: '120100', selectedAreaName: '天津市',
      formData: {
        name: "",
        idCard: "",
        area: "",
        address: "",
        mobile: "",
        agree: false,
      },
      popupVisible: false,
      curPage: 1, // 当前的分页 每次loadding curPage +=1
      // orderId: '',
      screenshotDataURL: "", // 快照
    };
  },
  // filters: {
  //   maskIdCard(value) {
  //      const prefix = value.substring(0, 4);
  // const suffix = value.substring(value.length - 4);
  // const hidden = '****';
  // return `${prefix}${hidden}${suffix}`;
  //   }
  // },
  computed: {
    getLabel() {
      return (option) => option.label; /* 返回每个选项的 label 值 */
    },
    getValue() {
      return (option) => option.value; /* 返回每个选项的 value 值 */
    },
  },
  mounted() {
    let screenWidth = document.documentElement.getBoundingClientRect().width;
    document.documentElement.style.fontSize = screenWidth / 10 + "px";
    window.paySuccess = this.paySuccess;
    // window.wechatPay = this.wechatPay;
  },
  methods: {
    // 快照

    async toImage() {
      const canvasBox = this.$refs.imageTofile;
      const options = {
        backgroundColor: "white", // 截图背景，不给则无
        useCORS: true, // 允许跨域，比如有的请求或者图片会跨域，建议都设置为true
        scale: 1, // 放大倍数
      };

      try {
        const canvas = await html2canvas(canvasBox, options);
        this.screenshotDataURL = canvas.toDataURL("image/png");
        // console.log(this.screenshotDataURL)
      } catch (error) {
        console.error(error);
      }
    },
    // window.webkit.messageHandlers.wechatPay.postMessage(`requesetType=12&type=1&ids=${this.orderId}&joinId=''&session=${paySession}`)
    pay() {
      let data = {
        type: "2",
        orderId: this.orderId,
        paymentAmount: this.price,
      };
      if (this.payType == 1) {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //androi
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        let paySession = window.sessionStorage.getItem("third-session");
        // console.log(paySession,'session')
        try {
          if (isIOS) {
            window.webkit.messageHandlers.wechatPay.postMessage(
              `requesetType=16&type=1&orderId=${this.orderId}&paymentAmount=${data.paymentAmount}&session=${paySession}`
            );
          }
          if (isAndroid) {
            // // window.android.share(data,'3')
            window.android.wxpay(
              `requesetType=16&type=1&orderId=${this.orderId}&paymentAmount=${data.paymentAmount}&session=${paySession}`
            );
          }
        } catch (error) {
          null;
        }
      } else {
        // let handData = {}
        payorder(data).then((res) => {
          // console.log(handData)
          // handData = res.data.data
          if (res.data.code === 0) {
            console.log(res.data);
            // 支付宝
            fuli_yundian_pay(res.data, "2");
          }
        });
      }
    },
    // pay() {
    //   let data = {
    //     type: this.payType, orderId: this.orderId
    //   }
    //   // let handData = {}
    //   payorder(data).then(res => {
    //     // console.log(handData)
    //     // handData = res.data.data
    //     if (res.data.code === 0) {
    //       if (this.payType == 1) {
    //         // 微信
    //         openPay(res, '1')

    //       } else if (this.payType == 2) {
    //         // 支付宝
    //         openPay(res.data, '2')

    //       }
    //     }
    //   })
    // },
    // 成功的函数
    paySuccess() {
      this.list = [];
      this.finished = false;
      this.loadding = false;
      this.text = "";
      this.onLoad();
      this.payshow = false;
      // this.payStatus = true
      this.popupVisible = false;
      // Toast('支付成功')
      // this.success = true
    },
    // base64ToBlob(base64Data) {
    //   let arr = base64Data.split(","),
    //     fileType = arr[0].match(/:(.*?);/)[1],
    //     bstr = atob(arr[1]),
    //     l = bstr.length,
    //     u8Arr = new Uint8Array(l);

    //   while (l--) {
    //     u8Arr[l] = bstr.charCodeAt(l);
    //   }
    //   return new Blob([u8Arr], {
    //     type: fileType,
    //   });
    // },
    // // blob转file
    // blobToFile(newBlob, fileName) {
    //   newBlob.lastModifiedDate = new Date();
    //   newBlob.name = fileName;
    //   newBlob = new window.File([newBlob], "png");
    //   return newBlob;
    // },
    // 查询地址

    searchAdress() {
      // searchAreaInfo查询地址
      searchAreaInfo().then((res) => {
        if (res.data.code == 0) {
          // console.log(res.data.code)
          let shi = res.data.data.cityAll;
          let qu = res.data.data.districtAll;
          let sheng = res.data.data.provinceAll;
          // 市
          const pcity_list = {};
          shi.forEach((element) => {
            // console.log(element.cityCode, element.cityName)
            let cityCode = element.cityCode;
            let cityName = element.cityName;
            pcity_list[cityCode] = cityName;
          });
          // console.log(pcity_list)
          // 省
          const province_list = {};
          sheng.forEach((element) => {
            let cityCode = element.provinceCode;
            let cityName = element.provinceName;
            province_list[cityCode] = cityName;
          });
          // console.log(province_list)

          // 区
          const county_list = {};
          qu.forEach((element) => {
            let cityCode = element.districtCode;
            let cityName = element.districtName;
            county_list[cityCode] = cityName;
          });
          // console.log(county_list)

          // this.areaList = { pcity_list, province_list, county_list }
          this.areaList.province_list = province_list;
          this.areaList.city_list = pcity_list;
          this.areaList.county_list = county_list;
          // console.log(this.areaList)
        }
        // console.log(res.data.code)
      });
    },
    onSubmit() {
      // const _this = this
      if (!this.formData.name) {
        Toast("请填写姓名");
        return;
      }

      if (!this.pattern.test(this.formData.idCard)) {
        Toast("请填写正确的身份证号");
        return;
      }
      if (!this.fieldName) {
        Toast("请选择所在地区");
        return;
      }
      if (!this.formData.address) {
        Toast("请输入详细地址");
        return;
      }
      const reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.formData.mobile)) {
        Toast("请填写正确的手机号");
        return;
      }
      if (!this.formData.agree) {
        Toast("请先阅读协议");
        return;
      }

      this.loadings = true;
      // this.$nextTick(() => {
      // })
      // console.log('submit', values);
      // console.log(this.screenshotDataURL)
      let data = {
        certNum: this.formData.idCard,
        userId: this.id,
        phoneNum: this.tsphone.all,
        address: this.formData.address,
        consigneeName: this.formData.name,
        connectPhone: this.formData.mobile,
        postProvinceCode: this.fieldValueshen,
        postCityCode: this.fieldValueshi,
        postDistrict: this.fieldValues,
        searchCategory: this.active,
        content: this.screenshotDataURL,
      };
      // console.log(data)

      xiadansuohao(data)
        .then((res) => {
          // console.log(res)
          if (res.data.code == 0) {
            // this.popupVisible = false
            this.loadings = false;
            this.price = res.data.data.byPayAccount;
            console.log("this.price", this.price);
            this.orderId = res.data.data.orderId;
            this.payshow = true;
            // setTimeout(() => {
            //   _this.$router.replace({
            //     name: `lianghaoPay`, // 使用命名路由名称来生成 URL
            //     params: {
            //       orderId: _this.orderId, // 通过路由参数传递参数
            //     },
            //     query: {
            //       id: _this.id, // 在查询参数中设置参数
            //     },
            //   });
            // }, 300);
          } else {
            this.loadings = false;
            Toast(res.data.message);
          }
        })
        .catch(() => {
          this.loadings = false;
        });
    },

    changeCity(value, selectedArea) {
      // value, selectedArea
      this.hasChanged = true; // 如果改变了 则添加一个true的标识
      this.fieldName =
        selectedArea[0].name +
        "/" +
        selectedArea[1].name +
        "/" +
        selectedArea[2].name;
      this.fieldValues = selectedArea[2].code;
      this.fieldValueshen = selectedArea[0].code;
      this.fieldValueshi = selectedArea[1].code;
      // console.log('&&&&&&&&&&&&', value, selectedArea)
    },
    onConfirms() {
      if (!this.hasChanged) {
        this.fieldName = "北京/北京市/东城区";
        // 地区编码
        this.fieldValues = "110101";
        this.fieldValueshen = "110000";
        this.fieldValueshi = "110100";
      }
      this.showArea = false;
    },

    onLoad() {
      // 异步更新数据
      // console.log("懒加载")
      getList({
        searchCategory: this.active,
        searchValue: this.text,
        userId: this.id,
      })
        .then((res) => {
          if (res.data.code == 0) {
            const hasAll = res.data.data.every((node) => {
              return this.list.includes(node); // 全部包括
            });

            if (hasAll) {
              console.log("this.list 中包含 res.data.data[0]");
              this.loading = true;
              this.finished = true;
            } else {
              console.log("this.list 中不包含 res.data.data[0]");
              this.list = this.list.concat(res.data.data);
              const set = new Set(this.list);
              this.list = [...set];
              this.loading = false;
              this.finished = false;
            }
            // if (res.data.data.length < 10) {
            //   this.loading = true;
            //   this.finished = true;
            // } else {
            //   this.loading = false;

            //   this.list = this.list.concat(res.data.data)
            // }
          } else {
            this.loading = true;
            this.finished = true;
            if (res.data.message) {
              Toast(res.data.message);
            }
          }
        })
        .catch(() => {
          this.loading = true;
          this.finished = true;
          // Toast(res.data.message)
        });
    },
    clearinfos() {
      this.list = [];
      this.finished = false;
      this.loadding = false;
      this.text = "";
      this.onLoad();
    },
    changeTabs() {
      this.list = [];
      this.finished = false;
      this.loadding = false;
      // this.text = ''
      this.onLoad();
    },
    searchPhones() {
      this.list = [];
      this.finished = false;
      this.loadding = false;

      this.onLoad();
    },

    close() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },
    onClickRight() {
      // this.$router.push('lianghaoinfo')
      this.$router.replace(`lianghaoinfo?id=${this.id}`);
    },
    // 信息回显
    getUserInfos() {
      getUserInfo({ userId: this.id }).then((res) => {
        if (res.data.code == 0) {
          // console.log(res.data.data)
          this.formData = {
            name: res.data.data.userName,
            idCard: res.data.data.cardId,
            mobile: res.data.data.phone,
          };
          // this.fieldName = res.data.data.province + '/' + res.data.data.city + '/' + res.data.data.county
          // // this.fieldValues = '110100'
          // // this.fieldValueshen = '110000'
          // // this.fieldValueshi = '110100'
          // this.fieldValues = res.data.data.postDistrictCode
          // this.fieldValueshen = res.data.data.postProvinceCode
          // this.fieldValueshi = res.data.data.postCityCode
          // console.log('省', this.fieldValueshen, '市', this.fieldValueshi, '区', this.fieldValues)
        }
      });
    },
    tanchuangzhanshi(phone) {
      //  certNum: this.formData.idCard, userId: this.id, phoneNum: this.tsphone.all, address: this.formData.address, consigneeName: this.formData.name, connectPhone: this.formData.mobile, postProvinceCode: this.fieldValueshen, postCityCode: this.fieldValueshi, postDistrict: this.fieldValues, searchCategory: this.active
      this.formData = {};
      this.getUserInfos();
      this.popupVisible = true;
      (this.activeIndex2 = [0, 0, 0]), (this.fieldName = "");
      this.fieldValues = "";
      this.hasChanged = false;
      setTimeout(() => {
        this.$refs.form.resetValidation();

        // this.$refs.areaadress.setDefault();
      }, 0);
      // console.log(phone)
      phone = phone.toString();
      this.tsphone.one = phone.slice(0, 3);
      this.tsphone.two = phone.slice(3, 7);
      this.tsphone.three = phone.slice(7, 11);
      this.tsphone.all = phone;
      this.$nextTick(() => {
        this.toImage();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.lianghaomain {
  background-color: #d7e9fe !important;
  min-height: 100vh;

  .div_yellow {
    width: 95%;
    height: 2rem;
    background-color: #fc9a2f;
    border-radius: 0.3784rem 0.3784rem 0rem 0rem;
    color: #ffffff;
    padding-top: 0.4054rem;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: space-around;
    box-sizing: border-box;
  }

  .lianghao_list_item {
    /deep/ .van-cell {
      // width: 48.5%;
      width: 4.6486rem;
      height: 2.4865rem;
      display: flex;
      // justify-content: space-between;
      // padding: 0.44rem;
      padding: 0.4rem;
      padding-bottom: 0rem;
      margin-bottom: 0.2rem;

      border-radius: 8px;
    }

    /deep/ .van-list {
      justify-content: space-between;
    }
  }

  .image-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 0.4054rem;
  }

  .phone-number {
    display: flex;
    // font-size: 0.4865rem;
    font-size: 0.43rem;
    font-weight: 600;
    color: #333333;
  }

  // .van-cell :nth-of-type(2n-1) {
  //   margin-right: 11px;
  // }

  .div_search {
    height: 1.8378rem;
    width: 95%;
    background-color: #fff;
    padding: 0 0.2703rem;
    box-sizing: border-box;
    border-radius: 0.2162rem;
    // margin-top: -0.1622rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.21rem;

    /deep/ .van-cell--clickable {
      height: 0.5405rem;
      width: 2.1622rem;
      box-shadow: none;
      padding: 0;
    }
  }

  /deep/ .nav_bar_top {
    margin-top: 0.4054rem;
    height: 0.6486rem;
    width: 100%;
    // padding: 0 10px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
  }

  // 下面三个控制弹出框左右两边的取消确认按钮
  /deep/ .van-picker__confirm {
    display: none;
  }

  /deep/.van-picker__cancel {
    display: none;
  }

  /deep/.van-picker__toolbar {
    justify-content: center !important;
  }

  // 确认按钮
  .confirm-btn {
    width: 9.0541rem;
    height: 1.2973rem;
    background: linear-gradient(360deg, #007aff 0%, #a3c6ff 100%);
    border-radius: 0.6486rem;

    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 0.4324rem;
    margin: 0 auto;
    margin-bottom: 1.0811rem;
  }

  /deep/.van-tabs__nav--line {
    width: 30%;
    background-color: #d7e9fe !important;
  }

  /deep/.van-overlay {
    width: 101%;
  }

  /deep/.van-tabs__line {
    background-color: #007aff;
    bottom: 0.6216rem;
    width: 0.8108rem;
  }

  .container {
    .van-image {
      width: 100%;
      height: 100%;

      /deep/ .van-image__img {
        width: 100% !important;
      }
    }
  }

  .buy_img {
    height: 4.5135rem;
    width: 9.1081rem;
    //   // background-color: #d54444;
    background: url("../img/phone.png");
    background-size: cover !important;
    border-radius: 0.5405rem 0.5405rem; // margin: 0 auto;
    // margin-top: -2.4324rem;
    position: absolute;
    bottom: 12.7rem;
    left: 50%;
    transform: translateX(-50%);
    // z-index: 9999;

    // padding: 30px;
    .buy_info {
      height: 4.5135rem;
      width: 9.1081rem;
      box-sizing: border-box;
      padding: 0.8108rem;
      color: #ffffff;

      .liuliang {
        margin-right: 0.4595rem;
        font-size: 0.3243rem;
      }

      img {
        width: 0.7027rem;
        height: 0.7027rem;
        margin-left: 0.1622rem;
      }

      .phone_info {
        font-weight: 600;
        font-size: 0.6757rem;
      }

      .songhuo {
        margin-top: 0.7568rem;
        font-size: 0.3784rem;
      }
    }
  }

  .form_item {
    box-sizing: border-box;
    padding: 0 0.5405rem;
    width: 9.2703rem;
    height: 1.4054rem;
    background: #f6f6f6;
    border-radius: 0.7027rem;
    margin-bottom: 0.3243rem;
    display: flex;
    align-items: center;
  }

  .terms-container {
    width: 9.2703rem;
    height: 1.4054rem;
    margin-bottom: 0.5rem;
    font-size: 0.3243rem;
    display: flex;
    justify-content: space-between;

    /deep/ .van-checkbox {
      display: block;
      // width: 20px;
      // height: 20px;
      margin-right: 5px;
    }

    .terms_discrip {
      width: 8.3rem;
    }
  }

  // /deep/.van-field__error-message {
  //   display: none;
  // }
  /deep/.van-field__error-message {
    position: absolute;
    top: 0.39rem;
    // right: 0;
    left: 0;
  }

  .sub_button {
    width: 6.973rem;
    height: 1.2973rem;
    background: linear-gradient(360deg, #007aff 0%, #a3c6ff 100%);
    border-radius: 0.6486rem;
  }

  .popup_index {
    // padding: 20px;

    /deep/.van-overlay {
      z-index: 99999;
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/deep/.van-cell {
  padding: 0;
  line-height: normal;
  line-height: 0.5135rem;
}

/deep/.van-picker__columns {
  top: 0.8108rem;
}

.pdf_block {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  // // position: absolute;
  // // top: 0;
  // // right: 0;
  // // left: 0;
  height: 100%;
  width: 100%;

  .scroll {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }

  iframe {
    // position: absolute;
    // bottom: 0;
    height: 100%;
    width: 100%;
  }

  // margin: auto;
  background-color: #fff;
}

.paypopup {
  box-sizing: border-box;
  border-radius: 20px 20px 0px 0px;

  /deep/ .van-icon-cross:before {
    display: none;
  }

  // .popup-success {
  //   box-sizing: border-box;

  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
  //   padding: 24px;
  //   align-items: center;
  //   height: 100%;

  //   .bottom-back {
  //     width: 60%;
  //     height: 48px;
  //     background: linear-gradient(180deg, #A3C6FF 0%, #007AFF 100%);
  //     opacity: 1;
  //     border-radius: 24px;

  //   }

  //   .tishi {
  //     font-size: 18px;

  //     font-weight: 400;
  //     line-height: 0px;
  //     color: #333333;
  //     letter-spacing: 6px;
  //     opacity: 1;
  //   }

  //   .popup-success {
  //     font-size: 18px;
  //     font-family: PingFangSC-Medium, PingFang SC;
  //     font-weight: 500;
  //     color: #333333;
  //     text-align: center;
  //     margin-bottom: 40px;
  //   }
  // }

  .popup-inner {
    //   // padding-top: 24px;
    padding: 24px;

    //   .title {
    //     font-size: 18px;
    //     font-family: PingFangSC-Medium, PingFang SC;
    //     font-weight: 500;
    //     color: #333333;
    //     text-align: center;
    //     margin-bottom: 40px;
    //   }

    //   .price {
    //     text-align: center;
    //     font-weight: 500;

    //     color: #3B99FF;

    //     .price-num {
    //       font-size: 28px;
    //     }
  }

  //   .bottom-btn {
  //     // position: absolute;
  //     // bottom: 40px;
  //     // width: 90%;
  //     // margin-left: 5%;
  //     margin-top: 20px;
  //     width: 100%;
  //     height: 48px;
  //     background: linear-gradient(360deg, #007AFF 0%, #A3C6FF 100%);
  //     border-radius: 24px;
  //   }

  // }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: visible;
  -webkit-overflow-scrolling: touch;
}

/deep/.van-list__finished-text {
  width: 95vw;
}

body {
  position: relative;
}
</style>
