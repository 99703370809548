<template>
  <div class="box">
    <p style="text-align: center;font-size: 18px"> 中国联通客户移动业务靓号协议</p>
    <p>甲方：</p>
    <p>号码:</p>
    <p>乙方：中国联合网络通信有限公司分公司</p>
    <p class="lefttext">
      本协议作为《中国联通客户入网服务协议》的补充协议，并与《中国联合网络通信有限公司XX分公司客户综合业务受理单》（以下简称业务受理单）合并使用。甲乙双方在平等自愿、公平诚信的基础上，基于对乙方移动通信服务的了解和需求，达成协议如下：
    </p>
    <p><span class="bigsize">第一条</span> 甲方所选择的移动业务靓号及相关的预存款、月承诺通信费、协议期及套餐等内容详见业务受理单。</p>
    <p>
      <span class="bigsize">第二条</span> 使用条件
    </p>
    <p>(一) 本协议中甲方预存款在开户当日返还，仅限该靓号使用，可延期消费，只在甲方办理过户或销户业务时方可退还余额部分。预存款可用于抵扣甲方每月承诺通信费（含国际及台港澳长途、国际及台港澳漫游、SP等费用）。</p>
    <p>
      (二) 当甲方月承诺靓号通信费与其选择的营销活动套餐有差异时，每月两者比较取高值。

    </p>
    <p>
      (三) 在协议期内，甲方不能办理转预付费业务、降低月承诺通信费、销户、停机保号、复装等业务。
    </p>
    <p> (四) 协议期内或协议期外，甲方办理过户业务时，新用户需重新签订靓号入网协议并交纳预存款，协议期及月承诺通信费自过户次月生效。</p>
    <p>
      (五) 若甲方为乙方现有2G或3G用户，在申请办理迁转4G业务时，甲乙双方所签原协议中的剩余预存款、月承诺通信费、剩余协议期仍按原协议执行，但过户、销户等规则应按照本协议执行。
    </p>
    <p>
      (六) 协议期满后，甲方可继续使用移动靓号，同时不受乙方规定的靓号办理条件限定。乙方另有约定的除外。

    </p>
    <p> (七) 甲方承诺自办理之日至退网期间靓号正常开通使用，并履行以上使用条件；若靓号超过缴费期90天且未缴费停机的，按照《中华人民共和国电信条例》的相关规定，乙方有权追缴欠费并停止服务、回收该靓号，同时本文约定自动解除。</p>

    <p>
      <span class="bigsize"> 第三条</span> 违约责任及承担
    </p>
    <p>（一）甲方违约责任</p>
    <p class="lefttext"> 1.甲方在协议期内发生销户等行为时，则甲方构成违约，应向乙方支付违约金，违约金=月承诺通信费（元）*3个月。</p>
    <p class="lefttext"> 2.乙方有权以甲方账户中剩余的预存款直接抵扣欠费、违约金及约定的相关费用，不足部分，乙方保留对甲方追缴的权利。违约金包括但不限于：因甲方欠费发生的逾期付款违约金等。</p>
    <p>（二）乙方违约责任</p>
    <p class="lefttext"> 乙方构成违约的，应按《中华人民共和国电信条例》及其他有关规定，承担违约责任。</p>
    <p><span class="bigsize">第四条</span> 协议生效</p>
    <p class="lefttext">本协议自双方签字盖章之日起生效，本协议到期时，如甲乙双方均未提出对套餐资费的修改要求，将继续执行本协议约定的套餐资费。</p>
    <p class="bigsize">（甲方承诺：本人已经充分、完整阅读并理解本协议所述全部条款及条件。）</p>
    <p class="lefttext"> 甲方：</p>
    <p class="lefttext"> 乙方：中国联合网络通信有限公司分公司</p>
    <p class="lefttext">协议签署日期：</p>
    <p style="height:50px"> </p>

  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  }
}
</script>

<style lang="less" scoped>
.lefttext {
  // margin-left: 30px;
  text-indent: 20px;
}

p {
  /* padding: 0px 20px; */
  margin: 5px 15px;
  /* text-align: center; */
}

.bigsize {
  font-weight: 600;
}

.box {
  // padding-top: 65px;
  height: 100%;
  // padding-bottom: 30px;
  background-color: #fff;
}
</style>